import axios from 'axios';
import moment from 'moment';
axios.defaults.baseURL = process.env.NODE_ENV !== 'production' ? "http://localhost:3006/app/api" : "https://portaleautoscuolacrocetta.it/app/api";
axios.defaults.timeout = 2 * 60 * 1000;
axios.defaults.withCredentials = true;
axios.defaults.credentials = 'same-origin';
axios.defaults.mode = 'no-cors';
axios.defaults.headers.post['Content-Type'] = 'application/json; charset=utf-8';


axios.interceptors.response.use(function (response) {
    // Do something with response data
    return response;
}, function (error) {
    // Do something with response error
    return Promise.reject(error);
});

var uploadLink = axios.create({
    baseURL: process.env.NODE_ENV !== 'production' ? "http://localhost:3006/app/api" : "https://portaleautoscuolacrocetta.it/app/api",
    timeout: 120 * 1000
});

var TOKEN_HEADER = null;

var configHeader = {
    "X-Token": TOKEN_HEADER,
    'Content-Type': 'application/json',
}

export const setToken = (_token_) => {
    TOKEN_HEADER = _token_;
    configHeader["X-Token"] = _token_;
}

const config = {
    "headers": {
        "content-type": 'multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s',
        "X-Token": TOKEN_HEADER,
    }
}



const LINK_API = process.env.NODE_ENV !== 'production' ? "http://localhost:3006/app/api" : "https://portaleautoscuolacrocetta.it/app/api";
export const VALIDATION_TOKEN = "/validation-token";
export const LOGIN_AUTH = "/login-auth";
export const INFO_USER_AUTH = "/init-user-auth";
export const MATERIALE_APP = "/materiale-app";
export const LOGOUT_AUTH = "/logout-auth";
export const INFO_USER = "/info-user";
export const INFO_USER_CORSI = "/info-user-corsi";
export const INFO_USER_CALENDARIO = "/info-user-calendario";
export const REMOVE_ORARIO_GUIDA_CALENDARIO = "/user-remove-orario-guida";
export const GET_ORARI_GUIDE_DAY = "/get-orari-guide-day";
export const UPDATE_ORARIO_STUDENT_CALENDARIO = "/update-orario-student-calendario";
export const ADD_ORARIO_STUDENT_CALENDARIO = "/add-orario-student-calendario";
export const INFO_STUDENT_CALENDARIO = "/info-student-calendario";
export const ORARI_CORSO_TEORICO = "/info-orari-corso-teorico";
export const SAVE_POSTO_AULA = "/save-posto-aula";
export const ANNULLA_POSTO_AULA = "/annulla-posto-aula";
export const CAPITOLI_MATERIALI_APP = "/capitoli-materiale-app";
export const LIST_ALLIEVI_INSTRUCTOR = "/allievi-istruttore";
export const CHECK_QRCODE_STUDENT = "/check-qrcode-student";
export const ADD_ORARIO_STUDENT_CALENDARIO_INSTRUCTOR = "/add-orario-student-calendario-instructor";
export const LINK_ADD_COIN_REGALO = "/add-coin-regalo";
export const LINK_ADD_COIN_PAGAMENTO = "/add-coin-pagamento";
export const GET_CALENDAR_INSTRUCTOR = "/get-calendar-instructor";
export const GET_ORARI_INSTRUCTOR = "/get-orari-instructor";
export const GET_ADD_ORARI_INSTRUCTOR = "/add-orario-guide";
export const DELETE_ORARIO_GUIDE = "/delete-orario-guide";
export const GET_METODI_PAGAMENTO = "/get-metodi-pagamento";
export const GET_SERVIZI = "/get-servizi";
export const GET_ISCRIZIONI = "/iscrizioni-utente";
export const GET_CALENDARIO_STUDENT = "/get-calendario-student";


export function requestWebService(link, dati) {
    return fetch(LINK_API + link, {
        method: 'POST',
        headers: configHeader,
        timeout: 2 * 60 * 1000,
        body: JSON.stringify(dati),
    })
        .then(response => response.json())
        .then(data => {
            if (process.env.NODE_ENV == 'production' && data && data.status && data.status == 401) {
                localStorage.removeItem('infouser')
                document.cookie = '__tknAC__=; Max-Age=-99999999;';
                window.location.href = '/';
            }
         
            if (data && data.tkn) {
                document.cookie = '__tknAC__=; Max-Age=-99999999;';
                var expires = moment().add(90, 'days').toString();
                var secure = 'secure';
                if (window.location.hostname == 'localhost') {
                    secure = '';
                }
                document.cookie = "__tknAC__=" + data.tkn + "; expires=" + expires + "; path=/;" + secure + ";";
            }
            return successHTTP(data);
        })
        .catch((error) => {

            return errorHTTP(error);
        });
}


export function requestWebServiceOLD(link, dati) {

    return axios
        .post(link, { ...dati, withCredentials: true, mode: "no-cors", credentials: 'same-origin' }, configHeader)
        .then(function (response) {
            return successHTTP(response.data);
        })
        .catch(function (error) {
            return errorHTTP(error);
        });
}

export function requestUpload(link, dati) {
    return uploadLink
        .post(link, dati, config)
        .then(function (response) {
            return successHTTP(response.data);
        })
        .catch(function (error) {
            return errorHTTP(error);
        });
}

const successHTTP = (res) => {
    return res;
};

const errorHTTP = (err) => {
    if (err == 'timeout of 10000ms exceeded') {
        return { error: true, message: 'timeout' };
    }
    return { error: true, ...err.response };
};
