import React, { useContext } from 'react';
import {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonButton,
    IonItem,
    useIonToast,
    IonLabel,
    IonSelect,
    IonSelectOption,
    IonDatetime
} from '@ionic/react';
import './AddGuida.css';
import { requestWebService, GET_METODI_PAGAMENTO, GET_SERVIZI, GET_ISCRIZIONI, GET_ORARI_GUIDE_DAY, ADD_ORARIO_STUDENT_CALENDARIO, GET_CALENDARIO_STUDENT, GET_CALENDAR_INSTRUCTOR } from '../utils/webservice';
import { AuthContext } from '../context/AuthContext';
import moment from 'moment';
import { RouteComponentProps, Router } from 'react-router';
import { compare } from '../utils/utils';
moment().locale("it");


interface AddGuidaPageProps
    extends RouteComponentProps<{
        iduser: string;
    }> { }

const AddGuidaPage: React.FC<AddGuidaPageProps> = ({ match }) => {
    const { infoUser } = useContext(AuthContext);
    //const [metodiPagamento, setMetodi] = React.useState([]);
    //const [servizi, setServizi] = React.useState([{ id: null, titolo: null }]);
    const [payments, setPayments] = React.useState([]);
    const [corsi, setCorsi] = React.useState<{ id_corso: number, id_iscrizione: number, coin: number, corso: string }[]>([]);
    const [noGuide, setNoGuide] = React.useState(false);
    const [noGuideCorso, setNoGuideCorso] = React.useState(false);
    const [noOrari, setNoOrari] = React.useState(false);
    const [calendar, setCalendar] = React.useState([]);
    const [selectedDate, setSelectedDate] = React.useState("");
    const [selectedOrario, setSelectedOrario] = React.useState<{ data: string, id: number, instructor: number, inizio: string, fine: string }>();
    const [orari, setOrari] = React.useState([]);

    const [present] = useIonToast();
    const [showLoading, setShowLoading] = React.useState(true);
    const [state, setState] = React.useState({
        spinning: false,
        calendar_student: [],
        coin: 1,
        student: '',
        qrcode: '',
        errorMsg: '',
        successMsg: '',
        orari: [],
        showAddCoin: false,
        courses_student: [],
        orario_saved: '',
        paymentMethods: "",
        corso: undefined,
        services: null,
        note: "",
        showConfirmModal: false
    });

    React.useEffect(() => {

        getInitialData();
    }, []);

    const getInitialData = async () => {
        //var metodi = await requestWebService(GET_METODI_PAGAMENTO, { id_autoscuola: parseInt(infoUser.autoscuola) });
        // var servizi = await requestWebService(GET_SERVIZI, { id_autoscuola: parseInt(infoUser.autoscuola) });
        var result = await requestWebService(GET_ISCRIZIONI, { iduser: match.params.iduser });
        var calendario = await requestWebService(GET_CALENDARIO_STUDENT, { iduser: match.params.iduser });

        var count = result.courses.length;
        result.courses.forEach((element: any) => {
            if (element["sumPayments"] !== element["importo_iscrizione"]) {
                //count = count - 1;
            }
        });

        if (count == 0 || result.courses.filter((e: any) => e.coin != null && e.coin != 0).length == 0) {
            setNoGuide(true);
        }

        setCalendar(calendario.data);
        setCorsi(result.courses.filter((x: any) => x.archiviato < 1));
        setPayments(result.payments);
        //setMetodi(metodi.data);
        //setServizi(servizi.data);
    }

    const getOrari = (value: any) => {
        setNoOrari(false);

        requestWebService(GET_ORARI_GUIDE_DAY, { data: moment(value).unix(), inst: infoUser.user }).then(async (res) => {
            var calendarioInstructor = await requestWebService(GET_CALENDAR_INSTRUCTOR, {});
            var orari = res.orari;
            var date = moment().format("YYYY-MM-DD");
            var dataValue = moment(value).format("YYYY-MM-DD");
            var array: any = [];
            var _orari: any = [];
            orari.forEach((item: any) => {
                var orainizio = moment(date + "T" + item.ora_inizio);
                var orafine = moment(date + "T" + item.ora_fine);
                var mm = orafine.diff(orainizio, 'minute') / 30;

                for (var i = 0; i < mm; i++) {
                    if (!(date == dataValue && moment().format("HH:mm") > moment(orainizio).add(30 * i, 'm').format("HH:mm"))) { }
                    array.push({
                        id: item.id,
                        data: moment(item.data.toString()).format("YYYY-MM-DD"),
                        inizio: moment(orainizio).add(30 * i, 'm').format("HH:mm"),
                        fine: moment(orainizio).add(30 * (i + 1), 'm').format("HH:mm"),
                        show: true
                    });

                }
            });
            _orari = array.sort(compare);

            if (calendarioInstructor != undefined) {
                var cal = calendarioInstructor.data.filter((element: any) => moment.unix(element.data).format("YYYY-MM-DD").toString() >= moment(value).format("YYYY-MM-DD").toString());
                if (cal.length != 0) {

                    // console.log(cal);
                    // console.log(moment.unix(cal[0].data).format("YYYY-MMM-DD").toString());
                    // console.log(moment.unix(cal[0].data_inizio).format("HH:mm").toString());
                    // console.log(_orari[0].data);
                    // console.log(_orari[0].inizio);


                    cal.forEach((item: any) => {
                        _orari = _orari.filter((element: any) =>
                            moment.unix(item.data).format("YYYY-MM-DD").toString() != element.data ||
                            moment.unix(item.data_inizio).format("HH:mm").toString() != element.inizio.toString());
                    });
                }
            }


            if (_orari.length == 0) {
                setNoOrari(true);
            } else {
                setOrari(_orari);
            }
        });
    }

    const addGuida = () => {
        var datainizio = moment(moment(selectedDate).format("YYYY-MM-DD") + " " + selectedOrario!.inizio).unix();
        var datafine = moment(moment(selectedDate).format("YYYY-MM-DD") + " " + selectedOrario!.fine).unix();

        var val = {
            id_iscrizione: state.corso!["id_iscrizione"],
            iduser: match.params.iduser,
            data: moment(selectedDate).unix(),
            data_inizio: datainizio,
            data_fine: datafine,
            inst: infoUser.user
        };

        requestWebService(ADD_ORARIO_STUDENT_CALENDARIO, val).then((res) => {

            if (res && res.status == 200) {
                present({
                    buttons: [],
                    message: 'Successo : guida aggiunta con successo',
                    position: 'bottom',
                    duration: 3000
                });
                window.history.back();
            } else {
                present({
                    buttons: [],
                    message: "Errore: impossibile salvare al guida",
                    position: 'bottom',
                    duration: 7500
                });
            }
        });
    }


    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton text="" defaultHref="/bacheca-instructor" />
                    </IonButtons>
                    <IonTitle>Aggiungi Guida</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <div style={{ textAlign: "center", marginTop: 20 }}>

                    {!noGuide && <>
                        <IonSelect
                            placeholder="Seleziona corso"
                            interfaceOptions={{ cssClass: "alert-wide" }}
                            style={{ borderBottom: "1px solid black", marginTop: 20 }}
                            onIonChange={(e) => {
                                var coin = payments.filter((element: any) => element["id_iscrizione"] == e.detail.value["id_iscrizione"]);
                                var coinSpesi = calendar.filter((element: any) => element["id_iscrizione"] == e.detail.value["id_iscrizione"]).length;
                                var totalCoin = 0;
                                coin.forEach((item: any) => {
                                    totalCoin += item.coin;
                                })
                                totalCoin = totalCoin - coinSpesi;
                                if (payments.filter((element: any) => element["method"] == "Da pagare").length > 10) {
                                    setNoGuideCorso(true);
                                }
                                // setNoGuideCorso(
                                //     totalCoin <= 0 || 
                                //     e.detail.value["corso_teorico"] != 2 || 
                                //     payments.filter((element: any) => element["method"] == "Da pagare").length > 10);
                                setState({ ...state, corso: e.detail.value, })
                            }}
                        >
                            {corsi.map((item, index) => {
                                return <IonSelectOption value={item} key={index}>{item.corso}</IonSelectOption>
                            })}
                        </IonSelect>


                        {state.corso != null && !noGuideCorso && <IonItem style={{ marginTop: 20, borderBottom: "1px solid black" }}>
                            <IonLabel >Seleziona data</IonLabel>
                            <IonDatetime
                                displayFormat="D MMM YYYY"
                                //min={moment().format().toString()}
                                max="2099"
                                value={selectedDate}
                                onIonChange={(e) => { setSelectedDate(e.detail.value!); getOrari(e.detail.value!); }}
                            ></IonDatetime>
                        </ IonItem>}
                    </>}

                    {selectedDate && !noOrari && <IonSelect
                        placeholder="Seleziona orario"
                        interfaceOptions={{ cssClass: "alert-wide" }}
                        style={{ borderBottom: "1px solid black", marginTop: 20 }}
                        onIonChange={(e) => {
                            setSelectedOrario(e.detail.value!);
                        }}
                    >
                        {orari.map((item, index) => {
                            return <IonSelectOption value={item} key={index}>{item["inizio"] + "-" + item["fine"]}</IonSelectOption>
                        })}
                    </IonSelect>}

                    {selectedDate && selectedOrario && !noGuide && !noGuideCorso && !noOrari && <IonButton style={{ marginTop: 20 }} color='warning' onClick={addGuida} >Aggiungi guida</IonButton>}

                    {noGuide && <h3 style={{ padding: 5 }}> Non puoi fissare guide per questo allievo </h3>}
                    {noGuideCorso && <h3 style={{ padding: 5 }}> Non puoi fissare guide per questo corso </h3>}
                    {noOrari && <h3 style={{ marginTop: 20, padding: 5 }}> Non hai orari disponibili per questa data</h3>}
                </div>
            </IonContent>
        </IonPage>
    );
};

export default AddGuidaPage;
