import {
	IonButtons,
	IonContent,
	IonHeader,
	IonItem,
	IonPage,
	IonText,
	IonTitle,
	IonToolbar,
	IonBackButton,
	IonLabel,
	IonSpinner,
	IonButton,
	IonSearchbar
} from '@ionic/react';
import './ListGuide.css';
import React from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import 'moment/locale/it';
import { requestWebService, LIST_ALLIEVI_INSTRUCTOR } from '../utils/webservice';
import SpinnerLoading from '../components/SpinnerLoading';

const ListAllieviInstructor: React.FC = () => {
	let { } = useParams<any>();

	const [list, setList] = React.useState<Array<any>>([]);
	const [loading, setLoading] = React.useState<boolean>(true);
	const [searchValue, setSearchValue] = React.useState("");
	React.useEffect(() => {
		getInfoStudentCalendario();
	}, []);

	const getInfoStudentCalendario = async () => {
		requestWebService(LIST_ALLIEVI_INSTRUCTOR, {}).then((res) => {
			if (res && res.status == 200) {
				setList(res.list || []);
				setLoading(false);
			} else {
				window.history.back();
				return false;
			}
		})
	}


	const searchAllievi = async (value?: string) => {
		requestWebService(LIST_ALLIEVI_INSTRUCTOR, { q: value || searchValue }).then((res) => {
			if (res && res.status == 200) {
				setList([...(res.list || [])]);
				setLoading(false);
			} else {
				alert("Errore: impossibile procedere con la richiesta.")
				return false;
			}
		})
	}

	const handleInput = (e: any) => {
		setSearchValue(e.target.value || "")
	}

	return (
		<IonPage id="content-menu">
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonBackButton text="" defaultHref="/bacheca" />
					</IonButtons>
					<IonTitle>Allievi</IonTitle>
				</IonToolbar>
				<IonToolbar>
					<IonSearchbar
						placeholder='Cerca allievo...'
						value={searchValue}
						showClearButton='always'
						onKeyUp={(e) => {
							if (e.key === 'Enter' || e.keyCode === 13) {
								searchAllievi();
							}
						}}
						onIonClear={() => { setSearchValue(""); searchAllievi("%"); }}
						onIonChange={handleInput}></IonSearchbar>
				</IonToolbar>
			</IonHeader>
			<IonContent>

				{loading && list.length == 0 && <SpinnerLoading />}
				{!loading && list && list.map((item, index) => {
					return <IonItem key={index}>
						<IonLabel className="ion-text-wrap">
							<IonText color="dark">
								<h3>{item.nome + " " + item.cognome}</h3>
							</IonText>
							{item.email && <p>Email : {item.email}</p>}
							{item.cellulare && <p>Cellulare : {item.cellulare}</p>}
							{item.telefono && <p>Telefono : {item.telefono}</p>}
							<IonButton ion-button color='warning' style={{ float: "right" }} routerLink={"/add-guida/" + item.iduser}>Aggiungi guida</IonButton>
						</IonLabel>
					</IonItem>
				})}
			</IonContent>
		</IonPage>
	);
};

export default ListAllieviInstructor;
