import React, { } from 'react';
import {
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	IonButtons,
	IonMenuToggle,
	IonButton,
	IonIcon,
	IonItem,
	IonLabel,
	IonText,
	IonCard,
	IonCardHeader,
	IonCardSubtitle,
	IonCardTitle,
	IonCardContent,
	IonBackButton
} from '@ionic/react';
import { link as linkIcon, menu, pin } from 'ionicons/icons';
import './MaterialeApp.css';
import { useQuery } from 'react-query';
import { requestWebService, MATERIALE_APP } from '../utils/webservice';
import { useParams } from 'react-router';
import SpinnerLoading from '../components/SpinnerLoading';
import ExploreContainer from '../components/ExploreContainer';


const MaterialeApp: React.FC = () => {
	let { tipo, capitolo } = useParams<any>();


	const { status, data, error } = useQuery<any, any, any>(
		'MATERIALE_APP',
		async () => {
			if (!['guida', 'teoria'].includes(tipo)) {
				throw new Error("Error");
			}
			var res = await requestWebService(MATERIALE_APP, { tipo: tipo, capitolo: capitolo });
			if (res && res.status == 200) {
				// var result = res.links.reduce(function (r: any, a: any) {
				// 	r[a.titolo_capitolo] = r[a.titolo_capitolo] || [];
				// 	r[a.titolo_capitolo].push(a);
				// 	return r;
				// }, Object.create(null));
				return res.links || [];
			}
			throw new Error(res.status + ' ' + res.data);
		},
		{
			retry: 1
		}
	);

	const TypeLink = ({ titolo, link }: any) => (<IonCardHeader>
		<IonCardSubtitle>
			<IonIcon icon={linkIcon} slot="start" />
		</IonCardSubtitle>
		<IonCardTitle>{titolo || ''}</IonCardTitle>

	</IonCardHeader>)

	const renderPage = () => {

		if (status === 'loading') {
			return <SpinnerLoading />;
		}
		if (status === 'error') {
			return <ExploreContainer msg={"Error Message : " + error.message} />;
		}

		if (status === 'success') {
			return (
				<React.Fragment>
					{data.length > 0 && <h1 style={{ textAlign: 'center', padding: '5px 5px', color: '#eab300' }}>{data[0].titolo_capitolo}</h1>}
					{data.map((item: any, index: number) => {
						if (item.video) {
							return <IonCard key={index}>
								<div className="container-header">
									<iframe width="100%" height="250" src={item.video} title="Video Autoscuola" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
								</div>
								<TypeLink titolo={item.titolo} link={item.link} />
								{item.link && <IonCardContent style={{ textAlign: 'center' }}>
									<IonButton href={item.link} target="_blank" fill="outline" slot="start">Visualizza</IonButton>
								</IonCardContent>}
							</IonCard>
						}
						if (item.immagine) {
							return <IonCard key={index}>
								<div className="container-header">
									<img src={item.immagine} />
								</div>
								<TypeLink titolo={item.titolo} link={item.link} />
								{item.link && <IonCardContent style={{ textAlign: 'center' }}>
									<IonButton href={item.link} target="_blank" fill="outline" slot="start">Visualizza</IonButton>
								</IonCardContent>}
							</IonCard>
						}
						if (item.link) {
							return <IonItem>
								{/* <IonIcon icon={linkIcon} slot="start" /> */}
								<IonCardContent>
									{item.titolo || ''}
									<br />
									{item.link && <IonButton href={item.link} target="_blank" fill="outline" slot="start">Visualizza</IonButton>}
								</IonCardContent>
							</IonItem>
						}
						return <React.Fragment></React.Fragment>;
					})}
				</React.Fragment>
			);
		}

		return <React.Fragment />;
	};

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonButtons slot="start">
							<IonBackButton text="" defaultHref="/bacheca" />
						</IonButtons>
					</IonButtons>
					<IonTitle>{tipo == 'guida' ? 'Materiali Guida' : 'Materiale Teoria'}</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent>{renderPage()}</IonContent>
		</IonPage>
	);
};

export default MaterialeApp;
